.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.title {
    font-size: 36px;
    font-weight: 600;
    color: #FFFFFF;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    border-radius: 15px;
    background: #FFFFFF;
    padding: 10px 15px;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.description__item {
    border-radius: 15px;
    padding: 10px 20px;
    color: #071A71;
    font-size: 16px;
    text-align: justify;
    box-shadow: 0 0 10px #cccccc;
    white-space: pre-line;
}

.sub-title {
    font-size: 24px;
    font-weight: 600;
    box-shadow: none;
    width: 100%;
    padding: 10px 0;
    color: #071A71;
 }

.description__item-with-photo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 15px;
    color: #071A71;
    font-size: 14px;
    text-align: justify;
    box-shadow: 0 0 10px #cccccc;
}

.description__item-with-photo__text {
    width: 100%;
    border-radius: 15px;
    padding: 10px 20px;
    color: #071A71;
    font-size: 16px;
    text-align: justify;
    box-shadow: 0 0 10px #cccccc;
    white-space: pre-line;
}

.description-photo {
    display: none;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 15px;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        color: #FFFFFF;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 87%;
        height: 100%;
        border-radius: 15px;
        background: #FFFFFF;
        padding: 10px 15px;
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .description__item {
        border-radius: 15px;
        padding: 5px 10px;
        color: #071A71;
        font-size: 14px;
        text-align: justify;
        box-shadow: 0 0 10px #cccccc;
    }

    .sub-title {
        font-size: 16px;
        font-weight: 600;
        box-shadow: none;
        width: 100%;
        padding: 10px 0;
        color: #071A71;
    }

    .description__item-with-photo {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 5px 10px;
    }

    .description__item-with-photo__text {
        width: 60%;
        font-size: 14px;
        text-align: justify;
        color: #071A71;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
    }

    .description-photo {
        display: flex;
        align-items: center;
        width: 40%;
    }

    .description-photo__img {
        object-fit: cover;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 10px #cccccc;
    }
}
